import React, {useCallback, useEffect, useState} from "react";
import NavigationContainer from "./components/navigationContainer/NavigationContainer";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AuthProvider} from "oidc-react";
import AuthChecker from "./components/authChecker/AuthChecker";
import {Button, CssBaseline, ThemeProvider, useMediaQuery} from "@mui/material";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound";
import LightTheme from "./components/theme/LightTheme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import {useSnackbar} from "notistack";
import Club from "./pages/Club/Club";
import ClubAdd from "./pages/Club/ClubAdd";
import User from "./pages/User/User";

function App() {

    const [waitingWorker, setWaitingWorker] = useState({});
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const {enqueueSnackbar} = useSnackbar();


    const updateServiceWorker = useCallback(() => {
            waitingWorker.postMessage({type: "SKIP_WAITING"});
            window.location.reload(true);
        }, [waitingWorker]
    );

    const onServiceWorkerUpdate = useCallback((registration) => {
        enqueueSnackbar('Es ist eine neue Version verfügbar. ', {
            action: (
                <Button onClick={() => updateServiceWorker()}>
                    Neu laden
                </Button>)
        });
        setWaitingWorker(registration.waiting);
    }, [enqueueSnackbar, updateServiceWorker])


    useEffect(() => {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: http://bit.ly/CRA-PWA
        serviceWorkerRegistration.register({
            onUpdate: onServiceWorkerUpdate,
            onSuccess: () => enqueueSnackbar('Diese App ist nun offline verfügbar!')
        });
    }, [enqueueSnackbar, onServiceWorkerUpdate]);

    function renderContent() {
        return (
            <AuthProvider authority={window._env_.AUTH_AUTHORITY}
                          clientId={window._env_.AUTH_CLIENT_ID}
                          redirectUri={window.location.href + "/"}
                          autoSignIn={false}
                          automaticSilentRenew={true}
                          scope={"openid profile"}
                          onSignIn={() => {
                          }}>
                <CssBaseline/>
                <AuthChecker>
                    <Router>
                        <Routes>
                            <Route path={"/"} element={<NavigationContainer/>}>
                                <Route index element={<Home/>}/>
                                <Route path={"user"} element={<User/>}/>
                                <Route path={"clubs/add"} element={<ClubAdd/>}/>
                                <Route path={"clubs"} exact element={<Club/>}/>
                                <Route path={"*"} element={<NotFound/>}/>
                            </Route>
                        </Routes>
                    </Router>
                </AuthChecker>
            </AuthProvider>
        )
    }

    let theme = LightTheme;
    if (prefersDarkMode) {
        theme = LightTheme;
    }

    return (
        <ThemeProvider theme={theme}>
            {renderContent()}
        </ThemeProvider>
    )


}


export default App;
