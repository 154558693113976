import React from "react";
import {Button} from "@mui/material";
import enrollSimple from "../../img/EnrollWhiteSimple.svg";
import PresentationStyles from "./PresentationStyles";

function Presentation(props) {

    const presentationStyles = PresentationStyles();
    const {handleLogin} = props;
    return (
        <div className={presentationStyles.rootContainer}>
            <div className={presentationStyles.titleContainer}>
                <img src={enrollSimple} className={presentationStyles.enrollLogo} alt={"enroll Logo"}/>
                <Button
                    variant={"contained"}
                    size={"large"}
                    color={"secondary"}
                    onClick={handleLogin}
                    fullWidth
                >
                    Login
                </Button>
            </div>

        </div>
    )

}

export default Presentation;