import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./index.css";
import {SnackbarProvider} from 'notistack';
import {CssBaseline, Grow} from "@mui/material";
import {Integrations} from "@sentry/tracing";
import * as Sentry from "@sentry/react";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.info("Disabled Sentry in development mode")
} else {

    Sentry.init({
        dsn: "https://14bd3c3fabd54109b39bcd69a0b4746c@sentry.cedsoft.de/4",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline/>
        <SnackbarProvider
            maxSnack={3}
            TransitionComponent={Grow}
            seco
        >
            <App/>
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
