import AbstractRest from "../AbstractRest";
import axios from "axios";

class AbuseEvaluationRest extends AbstractRest {

    constructor() {
        super("/admin/club");
    }

    getAll = () => {
        return axios.get(this.baseUrl + "/");
    }

}

export default AbuseEvaluationRest;