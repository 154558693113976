import {makeStyles} from "@mui/styles";

const StatementStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },
    child: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    message: {
        paddingTop: theme.spacing(2)
    }
}))
export default StatementStyles;