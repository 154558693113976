import {DataGrid} from '@mui/x-data-grid';
import {useEffect, useMemo, useState} from "react";
import UserAdminRest from "../../rest/admin/UserAdminRest";


function User() {

    const userRest = useMemo(() => new UserAdminRest(), []);

    const columns = [
        {field: 'id', headerName: 'ID', width: "300"},
        {field: 'forename', headerName: 'Vorname'},
        {field: 'surname', headerName: 'Nachname'},
        {
            field: 'email',
            headerName: 'Email',
            type: 'email',
            width: "200"
        },
        {
            field: 'createdAt',
            headerName: 'Erstellt am',
        },
    ];

    const [rows, setRows] = useState([]);

    useEffect(() => {
        userRest.findAll().then(response => {
            console.log(response.data)
            setRows(response.data);
        })
    }, [userRest])

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
        />
    );
}

export default User;
