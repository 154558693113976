import React, {useEffect} from "react";
import {useAuth} from "oidc-react";
import Presentation from "../presentation/Presentation";
import axios from "axios";
import Statement from "../statement/Statement";
import {Clear} from "@mui/icons-material";
import {Button} from "@mui/material";

function AuthChecker(props) {
    const auth = useAuth();
    const {children} = props;

    useEffect(() => {
        if (auth?.userData?.access_token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${auth.userData.access_token}`
        }
    }, [auth])

    function parseJwt(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    let content = (
        <Presentation handleLogin={auth.signInPopup}/>
    )

    if (auth.userData) {
        const jwt = parseJwt(auth?.userData?.access_token);
        const roles = jwt.realm_access.roles

        content = children

        console.log("roles", roles)
        if (!roles || !roles.includes("admin")) {
            console.log("hi")
            content = <div style={{height: "40vh"}}><Statement icon={<Clear/>} message={"Not permitted"}/><Button
                onClick={auth.signOut} fullWidth>Logout</Button></div>
        }
    }

    return content


}

export default AuthChecker;