import {makeStyles} from "@mui/styles";

const PresentationStyles = makeStyles(theme => ({
    rootContainer: {
        height: "100vh",
        display: "flex",
        padding: "5rem",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        background: "#292b2c"
    },
    titleContainer: {
    },
    enrollLogo: {
        height: "20rem",
        width: "auto",
        maxWidth: "100%"
    },
    welcome: {
        color: theme.palette.grey[50]
    },
    applicationTitle: {
        color: theme.palette.grey[200]
    }

}))
export default PresentationStyles;