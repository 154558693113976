import AbstractRest from "../AbstractRest";
import axios from "axios";

class UserAdminRest extends AbstractRest {
    constructor() {
        super("/admin/user");
    }

    getSelf() {
        return axios.get(this.baseUrl)
    }
}

export default UserAdminRest;