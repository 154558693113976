import React, {useEffect, useState} from "react";
import {Fade, Typography} from "@mui/material";
import StatementStyles from "./StatementStyles";

function Statement(props) {

    const loadingSpinnerStyles = StatementStyles();

    const [loadingTextShowing, setLoadingTextShowing] = useState(false);

    const {message, icon} = props;

    useEffect(() => {
        setTimeout(() => setLoadingTextShowing(true), 100)
    }, [])

    return (
        <div className={loadingSpinnerStyles.wrapper}>
            <div className={loadingSpinnerStyles.child}>
                <Fade in={loadingTextShowing}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {React.cloneElement(icon, {style: {fontSize: "2rem"}})}
                        <Typography variant={"body1"} className={loadingSpinnerStyles.message}>{message}</Typography>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

Statement.defaultProps = {
    message: "Loading"
}

export default Statement;