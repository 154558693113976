import {makeStyles} from "@mui/styles";

const LoadingSpinnerStyles = makeStyles(theme => {
    return ({
        wrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
        },
        child: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        },
        message: {

            paddingTop: theme.spacing(4)
        }
    })
})
export default LoadingSpinnerStyles;