import React, {useMemo, useState} from "react";
import {Button, Divider, Grid, MenuItem, Select, Stack, TextField} from "@mui/material";
import ClubRest from "../../rest/admin/ClubAdminRest";
import {useNavigate} from "react-router-dom";

function ClubAdd() {

    const clubRest = useMemo(() => new ClubRest(), []);
    const navigate = useNavigate();


    const [club, setClub] = useState({
        name: "",
        clubId: "",
        primaryColor: "#000000",
        secondaryColor: "#ffffff",
        user: {
            id: ""
        },
        address: {
            street: "",
            houseNumber: "",
            city: "",
            country: "de"
        },
        page: {
            title: "Neuer Club",
            text: "Dies ist ein neuer Club. Bitte verändere den Text hier, um deinen Verein ein bisschen besser vorzustellen."
        }
    });

    function handleSave(){
        clubRest.save(club).then(() => navigate("/clubs"))
    }

    function handleUpdateGeneral(event) {
        let newClub = {...club};
        console.log(newClub, event.target.name)
        newClub[event.target.name] = event.target.value;
        if(event.target.name === "name"){
            newClub["clubId"] = event.target.value.toLowerCase().replace(/\W/g, '');
        }
        setClub(newClub);
    }

    function handleUpdateAddress(event) {
        let newClub = {...club};
        newClub.address[event.target.name] = event.target.value;
        setClub(newClub);
    }

    function handleUpdateUserId(event) {
        let newClub = {...club};
        newClub.user.id = event.target.value;
        setClub(newClub);
    }

    function handleUpdatePage(event) {
        let newClub = {...club};
        newClub.page[event.target.name] = event.target.value;
        setClub(newClub);
    }

    return (
        <Stack spacing={2}>
            <TextField name={"name"} onChange={handleUpdateGeneral} label={"Name"} value={club.name}/>
            <TextField disabled onChange={handleUpdateGeneral} label={"name id"} value={club.clubId}/>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name={"primaryColor"} onChange={handleUpdateGeneral} label={"Primary Color"}
                                   type={"color"} fullWidth
                                   value={club.primaryColor}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name={"secondaryColor"} onChange={handleUpdateGeneral} label={"Secondary Color"}
                                   type={"color"} fullWidth
                                   value={club.secondaryColor}/>
                    </Grid>
                </Grid>
            </div>
            <TextField name={"user_id"} onChange={handleUpdateUserId} label={"Inhaber"} value={club.user.id}/>
            <Divider>Adresse</Divider>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField name={"street"} label={"Street"} onChange={handleUpdateAddress} fullWidth
                                   value={club.address.street}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name={"houseNumber"} placeholder={"House Number"} onChange={handleUpdateAddress}
                                   type={"number"} inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}} fullWidth
                                   value={club.address.houseNumber}/>
                    </Grid>
                </Grid>
            </div>
            <TextField name={"city"} onChange={handleUpdateAddress} label={"City"} value={club.address.city}/>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={club.address.country}
                label="Country"
                onChange={handleUpdateAddress}
            >
                <MenuItem value={"de"}>Germany</MenuItem>
            </Select>

            <Divider>Seitendaten</Divider>

            <TextField name={"title"} onChange={handleUpdatePage} label={"Titel"} value={club.page.title}/>
            <TextField name={"text"} multiline onChange={handleUpdatePage} label={"Text"}
                       value={club.page.text}/>

            <Button fullWidth variant={"contained"} onClick={handleSave}>Save</Button>

        </Stack>
    );

}

export default ClubAdd;
