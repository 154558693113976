import React, {useEffect, useState} from "react";
import {CircularProgress, Fade, Typography} from "@mui/material";
import LoadingSpinnerStyles from "./LoadingSpinnerStyles";

function LoadingSpinner(props) {

    const loadingSpinnerStyles = LoadingSpinnerStyles();

    const [loadingTextShowing, setLoadingTextShowing] = useState(false);

    const {message} = props;

    useEffect(() => {
        setTimeout(() => setLoadingTextShowing(true), 2000)
    }, [])

    return (
        <div className={loadingSpinnerStyles.wrapper}>
            <div className={loadingSpinnerStyles.child}>
                <CircularProgress/>
                <Fade in={loadingTextShowing}>
                    <Typography variant={"body1"} className={loadingSpinnerStyles.message}>{message}</Typography>
                </Fade>
            </div>
        </div>
    )
}

LoadingSpinner.defaultProps = {
    message: "Loading"
}

export default LoadingSpinner;