import React from "react";
import {
    AppBar,
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    CssBaseline,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {AccountCircleOutlined, Groups, Home, SupervisedUserCircle,} from "@mui/icons-material";
import NavigationContainerStyles from "./NavigationContainerStyles";
import {Link, Outlet} from "react-router-dom";
import {useAuth} from "oidc-react";

const drawerWidth = 240;


function NavigationContainer() {

    const classes = NavigationContainerStyles();
    const [value, setValue] = React.useState(0);
    const auth = useAuth();
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('sm'));


    function renderAccount() {
        let content = (
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={auth.signInPopup}
                color="inherit"
            >
                <AccountCircleOutlined/>
            </IconButton>
        )
        if (auth.userData) {

            const profile = auth?.userData?.profile;
            let initials = "N/A";
            let givenName = "Max";
            let familyName = "Mustermann";
            if (profile?.given_name && profile?.family_name) {
                givenName = profile.given_name;
                familyName = profile.family_name;
                initials = givenName.charAt(0) + familyName.charAt(0)
            }
            content = (
                <Tooltip title={givenName + " " + familyName}>
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        onClick={auth.signOut}
                        color="inherit"
                    >
                        <Avatar>{initials}</Avatar>
                    </IconButton>
                </Tooltip>
            )
        }
        return content;
    }

    if (!isLarge) {
        return (
            <Box sx={{pb: 7}}>
                <AppBar
                    position="fixed"
                    sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
                >
                    <Toolbar>
                        <Typography variant="h6" noWrap>
                            enroll Administration
                        </Typography>
                        <div className={classes.grow}/>
                    </Toolbar>
                </AppBar>
                <Toolbar/>
                <Outlet/>
                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                        <BottomNavigationAction component={Link} to={"/clubs"} label="Club"
                                                icon={<Groups/>}/>
                        <BottomNavigationAction label="Account"
                                                icon={<Avatar sx={{width: 24, height: 24}}/>}/>
                    </BottomNavigation>
                </Paper>
            </Box>
        );
    }


    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap>
                        enroll Administration
                    </Typography>
                    <div className={classes.grow}/>
                    {renderAccount()}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                }}
            >
                <Toolbar/>
                <List>
                    <ListItem button component={Link} to={"/"}>
                        <ListItemIcon><Home/></ListItemIcon>
                        <ListItemText primary={"Home"}/>
                    </ListItem>
                    <ListItem button component={Link} to={"/clubs"}>
                        <ListItemIcon>
                            <Groups/>
                        </ListItemIcon>
                        <ListItemText primary={"Clubs"}/>
                    </ListItem>
                    <ListItem button component={Link} to={"/user"}>
                        <ListItemIcon>
                            <SupervisedUserCircle/>
                        </ListItemIcon>
                        <ListItemText primary={"Benutzer"}/>
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Outlet/>
            </main>
        </div>
    )

}

export default NavigationContainer;
