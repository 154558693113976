import React from "react";
import {Typography} from "@mui/material";

function Home() {

    return (
        <>
            <Typography variant={"h2"}>
                Dashboard
            </Typography>
        </>
    )
}

export default Home;