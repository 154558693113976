import axios from "axios";

class AbstractRest {

    constructor(baseUrl) {

        this.baseUrl = window._env_.API_URL + "/v1" + baseUrl;
    }

    findAll = () => {
        return axios.get(this.baseUrl)
    }

    save = (entity) => {
        return axios.post(this.baseUrl, entity);
    }

}

export default AbstractRest;
