import React, {useEffect, useMemo, useState} from "react";
import ClubRest from "../../rest/admin/ClubAdminRest";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Link} from "react-router-dom";


function Club() {

    const [clubs, setClubs] = useState(null);
    const clubRest = useMemo(() => new ClubRest(), [])

    useEffect(() => {
        clubRest
            .findAll()
            .then(response => {
                console.log(response.data)
                setClubs(response.data);
            })
    }, [clubRest]);


    function renderContent() {
        let content = <LoadingSpinner/>
        if (clubs) {
            content = (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Club ID</TableCell>
                                    <TableCell align="right">City</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clubs.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="right">{row.name}</TableCell>
                                        <TableCell align="right">{row.clubId}</TableCell>
                                        <TableCell align="right">{row.address?.city}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button component={Link} to={"add"}>Hinzufügen</Button>
                </>
            )
        }
        return content
    }

    return renderContent()
}

export default Club;
